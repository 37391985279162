import DatePicker from "react-datepicker";
import { useState, React } from "react";
import { useEffect } from "react";

import { useRef } from "react";
import axios from "axios";
import http from "../../../../src/Redux/services/http-common.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import moment from "moment";
import searchImg from "../../../Assets/Images/searchImg.svg";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";

import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";


import { Sidebar } from "primereact/sidebar";

import { debounce } from "lodash";

import { APP_ROUTES } from "../../../application/Router/constants/AppRoutes";
import PredlabActionSidebar from "./PredlabActionSidebar.js";


function Consultation({}) {
  const location = useLocation();
  const receiverName = location.state?.receiverName;
  console.log(receiverName, "receiverName");
  const data = location.state?.data;
  const searchData = location.state?.searchData;
  const receiverdata = location.state?.receiverdata;
  // const year = location.state?.year;
  // const month = location.state?.month;
  const locationState = location.state || {};
  const year = locationState.year || new Date().getFullYear();
  const month = locationState.month || new Date().getMonth() + 1;
  //   const [editedDates, setEditedDates] = useState({});
  const navigate = useNavigate();
  const [modifiedServiceDate, setModifiedServiceDate] = useState();
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [rowData, setRowdate] = useState(data);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selecteDate, setSelectedDate] = useState("");
  const [allplan, setAllplan] = useState();
  const [planName1, setplanName1] = useState();
  const [planids, setplanids] = useState();
  const [searchMonth, setSearchMonth] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchPlan, setSearchPlan] = useState("");
  const [searchall, setSearchall] = useState(data);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [id, setId] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [value, setValue] = useState(searchData);
  const [myArray, setMyArray] = useState(searchall);

  const [updatedlist, setUpdatedlist] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [statuses] = useState(["Yet To Start", "In Progress", "Complete"]);
  const [consultationTypes] = useState(["In Person", "Home Visit", "Video"]);

  const [frommtime, setFromtime] = useState();
  const [totime, setTotime] = useState();
  const [serviceprovider, setServiceprovider] = useState();
  const [serviceproviderNo, setServiceproviderNo] = useState();

  // const [selectStatus, setSelectStatus] = useState(null);

  const op1 = useRef(null);
  const op2 = useRef(null);
  const [comment, setComment] = useState(" ");
  const [scheduledata, setScheduledata] = useState(rowData);

  const [isEditing, setIsEditing] = useState(false);

  const [getApi, setGetapi] = useState();

  const [rowid, setRowid] = useState();
  const [visible, setVisible] = useState(false);
  const [sharelinkvisible, setSharelinkvisible] = useState(false);
  const [position, setPosition] = useState("center");
  const [selectedFile, setSelectedFile] = useState(null);
  const [rowData1, setRowData1] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [sideDialog, setSideDialog] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [share, setShare] = useState(false);
  const [appID, setAppointmentId] = useState();
  const [careplanDataExcel, setCareplanDataExcel] = useState([]);

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setSideDialog(true);
  };
  const closeSidebar = () => {
    setSideDialog(false);
    toast("Appointment Booked");
  };
  //   const showSuccess = () => {
  //     toast.current.show({severity:'success', summary: 'Success', detail:'Appointment Booked', life: 3000});

  // }

  const showDialog = (rowData) => {
    setRowData1(rowData);
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const hideDialogSharelink = () => {
    setSharelinkvisible(false);
  };

  // const initialDate = new Date(year, month - 1, 1);
  const initialDate =
    locationState.year && locationState.month
      ? new Date(year, month - 1, 1)
      : null;

  console.log([data].comments, "help");

  // const transMonth = month || formattedMonth;
  // const transYear = year || formattedMonth;

  const moment = require("moment");

  const yearGet = moment().format("YYYY");
  const monthGet = moment().format("MM");

  const showSide = (position, rowData) => {
    console.log("Button clicked with rowData:", rowData);
    setPosition(position);
    setVisible(true);
  };

  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;

  const formattedYear =
    receiverName && selectedDate1
      ? moment(selectedDate1).format("YYYY")
      : receiverName
      ? ""
      : selectedDate1
      ? moment(selectedDate1).format("YYYY")
      : currentYear;

  const formattedMonth =
    receiverName && selectedDate1
      ? moment(selectedDate1).format("MM")
      : receiverName
      ? ""
      : selectedDate1
      ? moment(selectedDate1).format("MM")
      : currentMonth;

  console.log(currentMonth, "hello");

  const handleSearchSubmit = () => {
   
    let url = `${process.env.REACT_APP_CHE_BASEURL}patient/list?mobile=${searchName}&status=3`;

   
    http
      .get(url)
      .then((res) => {
        setSearchall(res.data);
       
        console.log(res.data, "yes");
        // dataFetched = true;
      })
      .catch((error) => {
        console.log(error, "went wrong");
     });
  };

  useEffect(() => {
    handleSearchSubmit();
  }, [
    searchName
  ]);
 

  console.log(formattedMonth, formattedYear, "formattedMonth");

  const handleOptionChange = (e) => {
    setSelectedOptionId(e.target.value);
  };

  console.log(selectedOptionId, "id is coming");





  const handleDropdownChange = (e) => {
    setSelectedId(e.value);
    setValue(e.target.value);
  };

  const handleDropdownStatus = (e) => {
    setSelectedStatus(e.value);
  };

  const handleChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  console.log(planName1, "service");

  const handleDateChange1 = (e) => {
    setSelectedDate1(e);
  };

  const header = (
    <div className="flex flex-wrap justify-content-end gap-2"></div>
  );



  const statusBodyTemplate = (rowData) => {
    let statusLabel, statusSeverity;
    switch (rowData.serviceStatus) {
      case "0":
        statusLabel = "Expired";
        statusSeverity = "danger";
        break;
      case "1":
        statusLabel = "Yet to Start";
        statusSeverity = "warning";
        break;
      case "2":
        statusLabel = "Appointment Booked";
        statusSeverity = "info";
        break;
      case "3":
        statusLabel = "Completed";
        statusSeverity = "success";
        break;
      case "4":
        statusLabel = "Request For Update";
        statusSeverity = "warning";
        break;
      case "5":
        statusLabel = "Cancelled";
        statusSeverity = "danger";
        break;
      case "6":
        statusLabel = "Re-Scheduled";
        statusSeverity = "warning";
        break;

      default:
        statusLabel = "";
        statusSeverity = "";
        break;
    }
    return <Tag value={statusLabel} severity={statusSeverity}></Tag>;
  };

  const filterOptions = [
    { label: "All", value: "", severity: "danger" },

    { label: "Yet to Start", value: "1", severity: "warning" },
    { label: "Appointment Booked", value: "2", severity: "info" },
    { label: "Completed", value: "3", severity: "success" },
    { label: "Request For Update", value: "4", severity: "warning" },
    { label: "Cancelled", value: "5", severity: "danger" },
    { label: "Expired", value: "0", severity: "danger" },
    { label: "Re-Scheduled", value: "6", severity: "danger" },
  ];

  

  




 



  return (
    <>
      <ToastContainer />
      <div style={{ padding: "10px" }}>
        <div className="maincareplan">
          {/* <div className="">Careplan Schedule : </div> */}

          {/* <div className='searchbar' style={{ marginBottom: "20px" }} title="Search by date">
            <input type="text" className='placeholder' placeholder=" Quick Search" name="search"
            />
            <img src={searchImg} alt={"Goto icon"} className='navImg' />

          </div> */}
        </div>
      </div>

      {/* search bar details */}
      <div style={{ marginTop: "5px" }}>
        <form
        // onSubmit={handleSearch}
        >
          <div className="maindivsearchbar">
            <div
              className="searchbar"
              style={{ marginBottom: "20px" }}
              title="Search by Name"
            >
             
                <input
                  type="text"
                  className="placeholder"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  placeholder="Search with Mobile no."
                  name="search"
           />

              <img
                src={searchImg}
                alt={"Goto icon"}
                className="navImg"
                onClick={handleSearchSubmit}
              />
            </div>

           
          
          

          
          </div>
        </form>
      </div>

      {searchall === undefined ? (
       ""
      ) : (
        <>
                  <DataTable
            value={searchall}
            paginator
            rows={50}
            rowsPerPageOptions={[5, 10, 25, 50]}
            filters={filters}
            globalFilterFields={["Status"]}
            header={header}
            tableStyle={{ padding: "10px" }}
            rowClassName={(rowData) =>
              rowData.serviceStatus === 2 ? "rowcolor" : ""
            }
          >
              <Column
  field="name"
  header="Patient Name"
  body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}
/>
            <Column field="mobile" header="Mobile"  />
           
          
            <Column field="email" header="Email"  />
           
            <Column
  field="Address"
  header="Address"
  body={(rowData) => `${rowData.city} ${rowData.state}`}
/>
   
            <Column
              header="Action"
              body={(rowData) => (
                <>
                  <span>
                   
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                        onClick={() => handleRowClick(rowData)}
                        style={{ cursor: "pointer" }}
                      ></i>
                   
                   
                 
                  </span>
                </>
              )}
            />

         
          </DataTable>

        

          <Sidebar
            visible={sideDialog}
            position="right"
            onHide={() => setSideDialog(false)}
            style={{ width: "480px" }}
          >
            <>
              <PredlabActionSidebar
                scheduledata={selectedRowData}
                
                handleSearch={handleSearchSubmit}
                closeFun={() => setSideDialog(false)}
                closeSidebar={closeSidebar}
              />
              {/* ... */}
            </>
          </Sidebar>


          
        </>
      )}
    </>
  );
}

export default Consultation;
