import DatePicker from "react-datepicker";
import { useState, React, useRef } from "react";
import { useEffect } from "react";
import http from "../../../src/Redux/services/http-common.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "../CarePlan/index.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import DoctorAppointmentViewPopup from "./DoctorAppointmentViewPopup.js";
import { Sidebar } from "primereact/sidebar";
import { AutoComplete } from "primereact/autocomplete";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import infobutton from "../../Assets/Images/infobutton.svg";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tag } from 'primereact/tag';
import { Dropdown } from "primereact/dropdown";
import { Toast } from 'primereact/toast';
import { Calendar } from "primereact/calendar";


function AppointmentReschedule({ }) {

    const toast = useRef(null);
    const [searchall, setSearchall] = useState();
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [sideDialog, setSideDialog] = useState(false);
    const [infoDetailsBar, setinfoDetailsBar] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
   
    const [options, setOptions] = useState([]);
    const [filteredDoctors, setFilteredDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [completedRows, setCompletedRows] = useState({});
 
  
   
    const handleSearchSubmit = () => {
        const formattedDate = fromDate ? fromDate.toISOString().split('T')[0] : '';
        let url = `${process.env.REACT_APP_DOC_URL}SlotAvailability/callBackRequest/list?callBackPrescriptionDate=${formattedDate}`;
        console.log(url,"gghuhu")
       
        http
            .get(url)
            .then((res) => {
                console.log(res.data,"gvgvvgfghfhb")
                setSearchall(res.data);
            })
            .catch((error) => {
                console.log(error, "went wrong");
            });
    };

    useEffect(() => {
        handleSearchSubmit();
    }, [fromDate]);
 
    const header = (
        <div className="flex flex-wrap justify-content-end gap-2"></div>
    );
    const search = (event) => {
        const filtered = options.filter((doctor) =>
            doctor.userName.toLowerCase().includes(event.query.toLowerCase())
        );
        setFilteredDoctors(filtered);
    };
    const handleSelect = (e) => {
        setSelectedDoctor(e.value);
    };

    const handleStatusChange = async (selectedValue, rowData) => {
        if (selectedValue === 'Completed') {
            const url = `${process.env.REACT_APP_DOC_URL}SlotAvailability/callBackRequest`;
            const payload = {
                callBackPrescriptionFlag: 'C',
                code: rowData.code,
            };
            
            console.log(url, "URL for request");
    
            http
                .put(url, payload)
                .then((res) => {
                    console.log(res.data, "Response Data");
                    if (res.status === 200) {
                        toast.current.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Prescription Enquiry Completed',
                            life: 3000
                        });
                        setCompletedRows((prev) => ({
                            ...prev,
                            [rowData.code]: true 
                        }));
                    }
                })
                .catch((error) => {
                    console.log(error, "Request failed");
                });
        }
    };
    
    return (
        <>
             <Toast ref={toast} />

            <div style={{  }}>
                <div className="maincareplan"></div>
            </div>
            <div style={{ marginTop: "5px",padding:"10px" }}>
            <h3>Select Date</h3>
            <Calendar
                value={fromDate}
                onChange={(e) => setFromDate(e.value)}
                placeholder="Select Date"
                dateFormat="yy-mm-dd"
                showIcon
            />
            </div>

            {searchall === undefined ? (
                <div
                    style={{
                        height: "1000px",
                        textAlign: "center",
                        justifyItems: "center",
                    }}
                >
                    <div>
                        <i
                            class="pi pi-spin pi-spinner"
                            style={{
                                fontSize: " 2rem",
                                marginTop: "200px",
                                color: "blue",
                                fontWeight: "700",
                            }}
                        ></i>
                    </div>
                </div>
            ) : (
                <>
                    <DataTable
                        value={searchall}
                        paginator
                        rows={50}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        globalFilterFields={["Status"]}
                        header={header}
                        tableStyle={{ padding: "10px" }}
                        rowClassName={(rowData) =>
                            rowData.serviceStatus === 2 ? "rowcolor" : ""
                        }
                    >
                        {/* <Column
                            field="patientName"
                            header="Patient Name"
                            sortable
                            body={(rowData, column) => (
                                <>
                                    <div class="">{rowData.patientName}</div>
                                    <div class="">{rowData.patientMobileNo}</div>
                                </>
                            )}
                        />
                        <Column field="whenAppointment" header="Appt Date" /> */}
                       
                        <Column sortable header="User Name" field="patientName" />
                        <Column sortable header="Number" field="mobile" />
                       
                       
                        <Column
                    header="Action"
                    body={(rowData) => (
                        <>
                            {completedRows[rowData.code] ? (
                                <Tag severity="success" value="Completed" style={{ backgroundColor: 'green', color: 'white' }} />
                            ) : (
                                <Dropdown
                                    value={null}
                                    options={[{ label: 'Completed', value: 'Completed' }]}
                                    placeholder="Select Action"
                                    onChange={(e) => handleStatusChange(e.value, rowData)}
                                />
                            )}
                        </>
                    )}
                />
                    </DataTable>
                   
                </>
            )}
        </>
    );
}

export default AppointmentReschedule;