import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CureBayLogo from "../../Assets/Images/CureBayLogo.svg";
import userIcon from "../../Assets/Images/nav_user_img.svg";
import logoutIcon from "../../Assets/Images/logout.svg";
import arr from "../../Assets/Images/arr.svg";
import moment from "moment";

import {
  clearDataFromStore,
  getDataFromStore,
} from "../../CommonUtils/commonUtils";
import "./index.css";
export default function AppHeader() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getDataFromStore("loggedIn")) {
      navigate("/login", { replace: true });
    }
  }, [navigate]);
  var showdate = new Date();
  var displaytodaysdate= showdate.getDate()+'/'+(showdate.getMonth("long")+1)+'/'+showdate.getFullYear();
   var dt= showdate.toDateString();
   const currDate = new Date().toLocaleDateString(
    "en-GB"
   );
   const currTime = new Date().toLocaleTimeString();
  const currDay = moment().format('dddd');  
 
  return (
    <div className="appHeader-container">
      {/* <img
        src="./images/logowithname.svg"
        style={{marginLeft:"20px"}}
        alt="cureBay Logo"
        title="CureBay Logo"
      /> */}
      <div className="date-time">
      <span className="date-time-day">{currDay}</span>
       <span>{currDate}</span> 
        
      </div>

      <UserDropDown />
    </div>
  );
}
const UserDropDown = () => {
  const [isClicked, setClicked] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    clearDataFromStore("loggedIn");
    clearDataFromStore("token");
    clearDataFromStore("userData");
    clearDataFromStore("rolecode");
    navigate("/login", { replace: true });
  };
  const handleDropDown = () => {
    setClicked(!isClicked);
  };
  const userDropDownRef = useRef(null);
  useEffect(() => {
    const handleOutSideClick = (e) => {
      if (!userDropDownRef?.current?.contains(e.target)) {
        setClicked(false);
      }
    };
    if (isClicked) {
      window.addEventListener("mousedown", handleOutSideClick);
    } else {
      window.removeEventListener("mousedown", handleOutSideClick);
    }
  }, [isClicked]);
  const [fullName, setFullName] = useState(null);
  const [timeStatus, setTimeStatus] = useState("");

  useEffect(() => {
    try {
      // const {
      //   user: { firstName, lastName },
      // } = JSON.parse(getDataFromStore("userData"));
      let userData = JSON.parse(localStorage.getItem("userData"));
      setFullName(userData?.firstName + " " + userData?.lastName);

      var today = new Date();
      var curHr = today.getHours();

      if (curHr < 12) {
        setTimeStatus("Good Morning !");
      } else if (curHr < 18) {
        setTimeStatus("Good Afternoon !");
      } else {
        setTimeStatus("Good Evening !");
      }
    } catch (e) {
      setFullName(null);
    }
  }, []);
  console.log(fullName,"full")
  return (
    <div ref={userDropDownRef} className="userDropDown-iconContainer">
      <div style={{display:"flex",marginTop: "10px", marginLeft: "10px"}}>
      <div className="userDropDown-icon" onClick={handleDropDown}>
        <img src={userIcon} alt={"user icon"} />
      </div>
      <div style={{display:"block",marginTop: "5px",
    marginLeft: "10px"}}>
      {/* <div>Prakash Shingadiya</div> */}
      {fullName && <div style={{marginRight:"20px"}}> {fullName}</div>}
      <span style={{fontSize:"14px"}}>Customer Service</span>
      </div>
      </div>
      {isClicked && (
        <div className="userDropDown-container">
         
          <div style={{ color: "rgba(255, 255, 255, 0.6)" }}>{timeStatus}</div>
          <div className="userDropDown-blockContainer">
            <NavLink
              to="/profile"
              // onClick={handleDropDown}
              className="userDropDown-block"
            >
              Profile{" "}
              <img src={arr} style={{ marginLeft: "auto" }} alt={"Goto icon"} />
            </NavLink>
          </div>
          <div className="userDropDown-logout" onClick={logout}>
            Logout{" "}
            <img
              style={{ marginLeft: "auto" }}
              src={logoutIcon}
              alt="logout icon"
            />
          </div>
        </div>
      )}
    </div>
  );
};
