import React, { useState, useEffect } from "react";
import "./index.css";
import moment from "moment";
import http from "../../../../src/Redux/services/http-common.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Patientreg = ({ stateList }) => {
  const [formData, setFormData] = useState({
    salutation: "",
    firstName: "",
    lastName: "",
    gender: "",
    maritialStatus: "",
    bloodGroup: "",
    state: "",
    uniqueId: "",
    dob: "",
    mobile: "",
    secMobileNumber: "",
    email: "",
  });

  const [err, setErr] = useState({});
  const [states, setStates] = useState([]);

  useEffect(() => {
    const fetchStates = async () => {
      const url = `${process.env.REACT_APP_BASEURL}state/list`;

      try {
        const response = await http.get(url);

        // Log the entire response object
        console.log("Response:", response.data);
        if (Array.isArray(response.data)) {
          setStates(response.data);
        } else {
          throw new Error("Response data is not in expected format");
        }

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Failed to fetch states:", error.message);
      }
    };

    fetchStates();
  }, []);
  console.log(states, "xdcfvgbhnj");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (e) => {
    setFormData({ ...formData, dob: e.target.value });
  };

  function allValuesAreEmpty(obj) {
    for (const key in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        obj[key].trim() !== ""
      ) {
        return false; // If any non-empty value found, return false
      }
    }
    return true;
  }
  const signUp = async (e) => {
  
    e.preventDefault();
    let error = {};
    error.firstName = formData.firstName ? "" : "Please Enter First Name";
    error.lastName = formData.lastName ? "" : "Please Enter Last Name";
    error.mobile = formData.mobile ? "" : "Please Enter Mobile No";
    error.uniqueId = formData.uniqueId ? "" : "Please Enter Unique ID";
    error.dob = formData.dob ? "" : "Please Select DOB";
    error.email = formData.email ? "" : "Please Enter Email";
    error.gender = formData.gender ? "" : "Please Enter Gender";
    error.bloodGroup = formData.bloodGroup ? "" : "Please Enter Blood Group";
    error.maritalStatus = formData.maritalStatus ? "" : "Please Enter Marital Status";
    error.salutation = formData.salutation ? "" : "Please Enter Salutation";
 
    if (!allValuesAreEmpty(error)) {
      setErr(error);
    }
    
    let payload = {
      address1: "",
      city: "",
      code: "",
      country: "",
      createdBy: "DHPADMIN",
      email: formData.email,
      firstName: formData.firstName,
      gender: formData.gender,
      isApproved: 1,
      lastName: formData.lastName,
      mobile: formData.mobile,
      pinCode: "",
      referredBy: "DHPADMIN",
      modifiedBy: "DHPADMIN",
      roleCode: "PATIENT",
      salutation: formData.salutation,
      source: "",
      state: formData.state,
      uniqueId: formData.uniqueId,
      status: 1,
      dob: moment(formData.dob).format("MM/DD/YYYY"),
      bloodGroup: formData.bloodGroup,
      maritalStatus: formData.maritalStatus,
      secMobileNumber: formData.secMobileNumber,
    };
   
  
    const url = `${process.env.REACT_APP_BASEURL}patient/`;
  
  
    try {
      const response = await http.post(url, payload);
  
      
      if (response.ok) {
        const data = await response.json();
        console.log("Registration successful:", data);
        toast("Registration successful")
        // Handle success (e.g., redirect, show success message, etc.)
      } else {
        // Handle server errors
        const errorData = await response.json();
        console.error("Registration error:", errorData);
        // Handle error response (e.g., display error messages)
      }
    } catch (error) {
      // Handle network errors
      console.error("Network error:", error);
    }
  };
  
  console.log(formData, "xdcfgvbhnj");

  return (
    <div style={{ padding: "40px" }}>
       <ToastContainer />
      <div className="patientform-container">
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <img
            src="./images/logowithname.svg"
            alt="cureBay Logo"
            title="CureBay Logo"
          />
        </div>
        <h1 style={{ color: "#004172" }}>Patient Register</h1>
        <form onSubmit={signUp}>
          <div>
            <div className="patientform-row">
              <div className="patientform-group">
                <label htmlFor="salutation">
                  Salutation <span className="patientform-text-danger">*</span>
                </label>
                <select
                  name="salutation"
                  value={formData.salutation}
                  onChange={handleChange}
                >
                  <option value="">Select Salutation</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Dr.">Dr.</option>
                </select>
                {err.salutation && (
                  <span className="patientform-error-text">
                    {err.salutation}
                  </span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="firstName">
                  First Name <span className="patientform-text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  maxLength="40"
                  placeholder="Enter Your Firstname"
                  onChange={handleChange}
                />
                {err.firstName && (
                  <span className="patientform-error-text">
                    {err.firstName}
                  </span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="lastName">
                  Last Name <span className="patientform-text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  maxLength="40"
                  placeholder="Enter Your Lastname"
                  onChange={handleChange}
                />
                {err.lastName && (
                  <span className="patientform-error-text">{err.lastName}</span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="gender">
                  Gender <span className="patientform-text-danger">*</span>
                </label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="O">Others</option>
                </select>
                {err.gender && (
                  <span className="patientform-error-text">{err.gender}</span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="maritialStatus">
                  Maritial Status{" "}
                  <span className="patientform-text-danger">*</span>
                </label>
                <select
                  name="maritialStatus"
                  value={formData.maritialStatus}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
                {err.maritialStatus && (
                  <span className="patientform-error-text">
                    {err.maritialStatus}
                  </span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="bloodGroup">
                  Blood Group <span className="patientform-text-danger">*</span>
                </label>
                <select
                  name="bloodGroup"
                  value={formData.bloodGroup}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="O-">O-</option>
                  <option value="O+">O+</option>
                  <option value="A-">A-</option>
                  <option value="A+">A+</option>
                  <option value="B-">B-</option>
                  <option value="B+">B+</option>
                  <option value="AB-">AB-</option>
                  <option value="AB+">AB+</option>
                </select>
                {err.bloodGroup && (
                  <span className="patientform-error-text">
                    {err.bloodGroup}
                  </span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="state">
                  Select State{" "}
                  <span className="patientform-text-danger">*</span>
                </label>
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {states.map((res) => (
                    <option key={res.code} value={res.code}>
                      {res.description}
                    </option>
                  ))}
                </select>
                {err.state && (
                  <span className="patientform-error-text">{err.state}</span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="uniqueId">
                  Last Four Digits of Aadhaar ID{" "}
                  <span className="patientform-text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="uniqueId"
                  name="uniqueId"
                  maxLength="4"
                  placeholder="Enter Your last four digits of Aadhaar Id"
                  onChange={handleChange}
                />
                {err.uniqueId && (
                  <span className="patientform-error-text">{err.uniqueId}</span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="dob">
                  Date of Birth{" "}
                  <span className="patientform-text-danger">*</span>
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  onChange={handleDateChange}
                />
                {err.dob && (
                  <span className="patientform-error-text">{err.dob}</span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="mobileNo">
                  Phone Number{" "}
                  <span className="patientform-text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  maxLength="10"
                  placeholder="Enter Your Phone Number"
                  value={formData.mobile}
                  onChange={handleChange}
                />
                {err.mobile && (
                  <span className="patientform-error-text">{err.mobile}</span>
                )}
              </div>

              <div className="patientform-group">
                <label htmlFor="secMobileNumber">Secondary Phone Number</label>
                <input
                  type="text"
                  id="secMobileNumber"
                  name="secMobileNumber"
                  maxLength="40"
                  placeholder="Enter Your Phone Number"
                  onChange={handleChange}
                  value={formData.secMobileNumber}
                />
              </div>

              <div className="patientform-group">
                <label htmlFor="email">
                  Email <span className="patientform-text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  maxLength="40"
                  placeholder="Enter Your Email"
                  onChange={handleChange}
                />
                {err.email && (
                  <span className="patientform-error-text">{err.email}</span>
                )}
              </div>
            </div>

            <div className="form-action-container">
              
              <button
                type="submit"
                className="form-action-button"
                // onClick={signUp}
              >
                Sign Up
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Patientreg;
