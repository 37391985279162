import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { ToastContainer, toast } from "react-toastify";
import http from "../../../../src/Redux/services/http-common.jsx"

function PredlabActionSidebar({
  scheduledata,
  refreshData,
  closeFun,
  handleSearch,
  closeSidebar,
}) {
  const props = scheduledata;
  const toast = useRef(null);
  console.log( props, "jhgkjbhklnjkl");

  const [doclist, setDocList] = useState("");
  const [modifiedServiceDate, setModifiedServiceDate] = useState();
  const [frommtime, setFromtime] = useState();
  const [serviceprovider, setServiceprovider] = useState();
  const [serviceproviderNo, setServiceproviderNo] = useState();
  const [comment, setComment] = useState();
  const [docavailabilityTime, setDocavailabilityTime] = useState();
  const [selectAvailabilityTime, setselectAvailabilityTime] = useState(null);

  const [selectConsult, setSelectConsult] = useState("V");
  const [selectdocList, setSelectdocList] = useState(null);
  const [selectlabList, setSelectlabList] = useState(null);
  const [selectStatus, setSelectStatus] = useState(null);
  const [selectStatus1, setSelectStatus1] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openDialog, setDialog] = useState(false);
  const [seoid, setSeoid] = useState();
  const [doctortlistApi, setDoctortlistApi] = useState();
  const [lablistApi, setlablistApi] = useState();
  const [nurselistApi, setnurselistApi] = useState();
  const [locationId, setLocationId] = useState(null);
  const [hospitslId, sethospitalId] = useState();
  const [locationIdNurse, setLocationIdNurse] = useState();
  const [hospitslIdNurse, sethospitalIdNurse] = useState();
  const [lablocationId, setlablocationId] = useState();
  const [labtestName, setlabtestName] = useState();
  const [selectlabtestName, setselectlabtestName] = useState();
  const [preferTime, setPreferTime] = useState("");
  const [preferDateTime, setPreferredDate] = useState();
  const [isset, isIsset] = useState(false);
  const [preferDate, setPreferDate] = useState();
  const [tickDialog, setTickDialog] = useState(false);
  const [ErrorDialog, setErrorDialog] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState(false);
  const [timeMessage, setTimeMessage] = useState("");
  const [error, setError] = useState(null);

  const [errorMessages, setErrorMessages] = useState({
    appointmentDate: "",
    scheduleTime: "",
    serviceProviderName: "",
    consultationReason: "",
    consultationType: "",
  });
  const [loading, setLoading] = useState(false);
 
  
  // const [fromTime, setFromTime] = useState("");
  // const [toTime, setToTime] = useState("");

  // Custom function to generate time slots at 15-minute intervals
  const generateTimeSlots = () => {
    const timeSlots = [];
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0); // Set the start time to midnight

    while (startTime.getHours() < 12) {
      const formattedTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      timeSlots.push(formattedTime);
      startTime.setMinutes(startTime.getMinutes() + 15);
    }

    return timeSlots;
  };

  const limitTimeForLab = (timeParam) => {
    console.log(timeParam, "sdpfdibdhpisdhpb");

    if (moment(preferDate).isSame(moment().format("YYYY-MM-DD"))) {
      let current_time_in_hour = moment().hour();

      let am_or_pm = timeParam.label.substring(0, 5).split(" ")[1];

      let time_in_hours = Number(timeParam.label.substring(0, 5).split(" ")[0]);

      if (am_or_pm == "PM" && time_in_hours != 12) {
        time_in_hours += 12;
      }

      if (current_time_in_hour + 3 >= time_in_hours) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const convertTime = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");

    let [hours] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}`;
  };

  const handleChange = (e) => {
    let value = e?.target?.value;

    setPreferTime(value);

    let time = convertTime(value.substring(0, 5));

    localStorage.setItem("preferTime", JSON.stringify(time));

    localStorage.setItem("preferValue", JSON.stringify(value));

    localStorage.setItem("preferDate", JSON.stringify(preferDate));
  };

  const changeDate = (date) => {
    setPreferDate(moment(date).format("yyyy-MM-DD"));
    date = moment(date).format("yyyy-MM-DD HH:mm:ss");

    setPreferredDate(date);
  };

  function handleEditClick() {
    setIsEditing(true);
  }

  useEffect(() => {
    let freeTextSearchValue = "";

   
    http
      .get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}doctors?freeTextSearch=${freeTextSearchValue}&pageNo=1&pageSize=100`
      )
      .then((res) => {
        setDoctortlistApi(res.data);

        let docList = res.data.map((obj) => {
          return obj.userName;
        });
        
        setDocList(docList);

        let seoId = res.data.map((obj) => {
          return obj.seoNameId;
        });
        setSeoid(seoId);

        console.log(res.data, "qwertymnbvcx");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);
  console.log(seoid, "doctortlist");





  console.log(lablocationId, "qwertymnbvcx");

  const dropdownOptionConsult = [
    // { name: 'Eclinic', value: 'E' },
    // { name: "Home Visit", value: "H" },
    { name: "Video", value: "V" },
    // { name: "Phone", value: "P" },
  ];
  const dropdownOptionConsultnurse = [{ name: "Home Visit", value: "H" }];

 




  const findServiceProviderDoc = (serviceName) => {
    const foundProvider = dropdownOptions.find(option => option.label === serviceName);

    return foundProvider ? foundProvider.value : null;
  };


  useEffect(() => {
    if (selectdocList) {
      fetchLocationId(selectdocList.id);
    }
  }, [selectdocList]);

  const fetchLocationId = (doctorId) => {

    http
      .get(
        `${process.env.REACT_APP_BASEURL}User_Hospital_Location/list?userId=${doctorId}&status=1&source=b2c`
      )
      .then((response) => {
        console.log(response.data[0], "apidata");

        let locationData = response.data[0].locationId;

        setLocationId(locationData);
        let hospitalIdData = response.data[0].hospitalId;
        sethospitalId(hospitalIdData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  const iconClassName = classNames("pi", "pi-calendar");

  const formatFromtime = frommtime ? moment(frommtime).format("HH:mm") : "";
  const updatedTime = moment(formatFromtime, "HH:mm")
    .add(15, "minutes")
    .format("HH:mm");

  const updatedToTimeDoc = moment(selectAvailabilityTime, "HH:mm")
    .add(15, "minutes")
    .format("HH:mm");
  console.log(updatedToTimeDoc, "time")

  var stringNumber = props.cpscSeqId;
  var number = parseInt(stringNumber);

  var appid = props.appointmentid;

  console.log(updatedTime, updatedToTimeDoc, "time")


 

  const userCode = selectdocList ? selectdocList?.code : "";

  const bookedScheduledate = modifiedServiceDate
    ? moment(modifiedServiceDate).format("MM/DD/YYYY")
    : "";

  console.log(selectConsult, "qwerty");

  const handleValidation = () => {
    let hasError = false;
    const newErrorMessages = {
      appointmentDate: "",
      scheduleTime: "",
      serviceProviderName: "",
      consultationReason: "",
      consultationType: "",
    };
  
   
    if (isEditing) {
      if (!bookedScheduledate) {
        newErrorMessages.appointmentDate = "* Appointment date is required";
        hasError = true;
      }
     
    }

    if (isEditing) {
      if ( !selectdocList) {
        newErrorMessages.serviceProviderName = "* Select a Doctor";
        hasError = true;
      }
    }

    if (isEditing) {
        if (!selectAvailabilityTime) {
          newErrorMessages.scheduleTime = "* Shedule Time is required";
          hasError = true;
        }
       
      }
      if (isEditing) {
        if (!comment) {
          newErrorMessages.consultationReason = "* Comment is required";
          hasError = true;
        }
       
      }
  
    setErrorMessages({ ...errorMessages, ...newErrorMessages });
  
    if (!hasError) {
      setDialog(true); 
    } else {
      setIsEditing(true); 
    }
  
    return hasError;
  };


  useEffect(() => {

    http
      .post(
        `${process.env.REACT_APP_BASEURL}medicare/getDoctorAvailability`,
        {
          userId: selectdocList?.code,
          fromDate: bookedScheduledate ,
        }
      )
      .then((res) => {

        setDocavailabilityTime(res.data, "response");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
    
  }, [selectdocList, bookedScheduledate]);

  const dropdownOptions = doctortlistApi?.map((item) => (
    // console.log(item,"fghnjmk")
    {
   
    label: item?.userName,
    value: {
      id: item?.seoNameId,
      name: item?.userName,

      email: item?.email,
      code: item?.userId,
      number: item?.contactNo,
      amount: item?.videoConsultCharges
    },
  }
));


console.log(doctortlistApi,selectdocList?.amount,"cfvgbhn")



  console.log(selectAvailabilityTime, "selectAvailabilityTime")


  const handleSubmit = (e, submit) => {
    e.preventDefault();

    let txnId = Math.floor(Math.random() * 300000000000);

    const hasError = handleValidation();
  
    if (!hasError) {
      setLoading(true);
      http
        .post(
          `${process.env.REACT_APP_BASEURL}PatientAppointment/`,
          {
            consultationsReason: comment,

            consultationsType: "V",

            createdBy: props.code,

            fromTime: selectAvailabilityTime,

            hospitalId: hospitslId,

            locationId: locationId,
            modifiedBy: props.code,
            patientEmail:props.email,
            patientId: props.code,
            patientMobileNo: props.mobile,
            patientName: props.firstName,
            toTime: updatedToTimeDoc,
            userEmail: selectdocList.email,
            userId: selectdocList.code,
            userMobile: selectdocList.number,
            userName: selectdocList.name,
               // "userQualification": "MBBS",

            // "userSalutation": "Dr",

            whenAppointment: bookedScheduledate,
            amount:  selectdocList.amount,
            txnId: txnId,
            relation: "Self",
            totalAmount: 0,
            payMode: "FULLDISCOUNT",
            voucherCode: "",
            voucherTransId: null,
            membershipId: "978432874237060",
            status: 1

          
          }
        )
        .then((response) => {
          setDialog(false);
          closeFun();
          handleSearch();
          setLoading(false);
          closeSidebar();
        })
        // .catch((error) => {
        //   if (error.response && error.response.status === 400) {
        //     console.log(error.response?.data.details[0], "fvgvhscbcjnk"); // Use error.response instead of response

        //     setErrorDialog(true);
        //     // setDialog(false);

        //     setErrorMsg(error.response?.data.details[0]);

        //     toast(error.response?.data.details[0]); // Use error.response instead of response
        //   } else {
        //     console.error(error);
        //   }
        // });
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setErrorDialog(true);
            setErrorMsg(error.response?.data.details[0]);
            toast(error.response?.data.details[0]);
          } else {
            console.error(error);
          }
        })
        .finally(() => {
          // setLoading(false); // Set loading to false regardless of success or failure
        });

    }
  };

 

  const [startTime, endTime] = preferTime.split(" - ");
  const startTimeFormat = moment(startTime, ["h A"]).format("HH:mm");
  const endTimeFormat = moment(endTime, ["h A"]).format("HH:mm");
  console.log(startTimeFormat, endTimeFormat, "asdfgghjjkkl");




  const onFilter = (event) => {
    if (event.query) {
      const filteredOptions = dropdownOptions.filter((option) =>
        option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setSelectdocList(filteredOptions);
    } else {
      setSelectdocList(dropdownOptions);
    }
  };
 
 

  const toastBC = useRef(null);

  const clear = (submit) => {
    toastBC.current.clear();
    submit && show();
  };

  const show = () => {
    toast.current.show({
      severity: "success",
      summary: "Submission Received",
      detail: "Thank you, we have received your submission.",
    });
  };

  const closeBothFunct = () => {
    setDialog(false);
    closeFun();
  };

  const currentDate = moment().startOf("day"); // Get the current date at the start of the day
  
//   const minDatenew = effectiveStartDate.isBefore(currentDate)
//     ? currentDate.toDate()
//     : effectiveStartDate.toDate();

//   const minDatenew1 = new Date();


  const currentTime = moment();


  const modifiedServiceDateMoment = moment(modifiedServiceDate);


  const isToday = modifiedServiceDateMoment.isSame(currentTime, "day");

 
  let minTime = moment().startOf("day").hour(7); 
  let maxTime = moment().startOf("day").hour(21); 

  if (isToday) {
    
    minTime = moment().max(currentTime);
  }

  const onModifyDate = (e) => {
    setModifiedServiceDate(e)
    onChangedDocList(e)
  }
  const onChangedDocList = (e) => {

    let freeTextSearchValue = "";

  
    http
      .get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}doctors?freeTextSearch=${freeTextSearchValue}&pageNo=1&pageSize=100`
      )
      .then((res) => {
        let docURL = `${process.env.REACT_APP_COMMON_BASEURL}DoctorListAsPerLeave/list?leaveDate=${moment(e).format("yyyy/MM/DD")}`
        http.get(docURL).then((res2) => {
          if (res2.data && res2.data.length) {
            let withoutLeave = res.data.filter(item1 => !res2.data.some(item2 => item1.userId.toLowerCase() === item2.userId.toLowerCase()));
            setDoctortlistApi(withoutLeave);
            let docList = withoutLeave.map((obj) => {
              return obj.userName;
            });
            setDocList(docList);
            let seoId = withoutLeave.map((obj) => {
              return obj.seoNameId;
            });
            setSeoid(seoId);
          } else {
            setDoctortlistApi(res.data);
            let docList = res.data.map((obj) => {
              return obj.userName;
            });
            setDocList(docList);
            let seoId = res.data.map((obj) => {
              return obj.seoNameId;
            });
            setSeoid(seoId);
          }
        })
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });

  }
  console.log(serviceprovider?.number, "service")
  return (
    <>
      <ToastContainer />
      <Toast ref={toast} />
      <Toast ref={toastBC} position="bottom-center" />

      {/* <button label="Success" className="p-button-success" onClick={showSuccess} >gvbnjmk,</button> */}

      <div className="">
        <form>
          <div>
            <h2 style={{ color: "black", marginTop: "0px" }}>
              {" "}
              Add Appointment Details
            </h2>
           
              <button
                type="button"
                onClick={handleEditClick}
                className="editinfor"
              >
                Edit information
              </button>
           
          </div>
          {/* <button>Edit </button> */}
          <div className="row ">
            

           

            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Appointment date : </label>
              </div>
              <div>
                {isEditing ? (
                  <>
                  
                  
                      <DatePicker
                        showIcon
                        selected={modifiedServiceDate}
                        // minDate={minDatenew}
                        onChange={(e) => onModifyDate(e)}
                        placeholderText="select"
                        customInput={
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <input
                              type="text"
                              className="inputfield"
                              // className="form-control modifieddate"
                              name="whenAppointment"
                              value={
                                modifiedServiceDate
                                  ? modifiedServiceDate.toLocaleString(
                                    "en-GB",
                                    {
                                      day: "numeric",
                                      month: "numeric",
                                      year: "numeric",
                                    }
                                  )
                                  :
                                 ""
                              }
                              readOnly
                            />
                            <i
                              className="fa fa-calendar  fa-pencil-square-o1 fontsome123"
                              aria-hidden="true"
                            ></i>
                          </div>
                        }
                      />
                    
                  </>
                ) :
            
                  ""
                }
                <div style={{ color: "red", marginTop: "12px", fontSize: "14px" }}>
                  {errorMessages.appointmentDate}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Doctor Name :</label>
              </div>
              <div style={{ width: "60%" }}>
                {isEditing ? (
                  <>
  
                        <Dropdown
                          id="disease"
                          name="docnames"
                          value={selectdocList ? selectdocList : findServiceProviderDoc(props.serviceProviderName)}
                          // value={   props?.serviceProviderName ? props?.serviceProviderName : selectdocList}
                          options={dropdownOptions}
                          onChange={(e) => setSelectdocList(e.value)}
                          // placeholder="Select Doctor"
                          placeholder="Select Doctor"
                          className="dropdowntd"
                          optionLabel="label"
                          filter
                          filterBy="label"
                          filterMatchMode="contains"
                          filterPlaceholder="Type to filter"
                          filterInputAutoFocus
                          onFilter={onFilter}
                        />
                        </>
                      ) 
                       : 
                     
                        ""
                      

                     
                    }
                
              

                <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                  {errorMessages.serviceProviderName}
                </div>

                {/* <input type="text" placeholder="service Provider Name" className="inputfield" value={props.serviceProviderName}/> */}
              </div>
            </div>

        
            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Schedule Time :</label>
              </div>
              <div style={{ width: "60%" }}>
                {isEditing ? (
                  <>
                    <div>
                      {
                        docavailabilityTime && selectdocList ? (

                          <Dropdown
                            id="disease"
                            name="doctime"
                            // value={ props.fromTime ? props.fromTime :selectAvailabilityTime}
                            value={selectAvailabilityTime}
                            // options={docavailabilityTime?.map((time) => ({ label: time, value: time }))}
                            options={Object.keys(docavailabilityTime)?.map((time) => ({
                              label: time,
                              value: time,
                              disabled: docavailabilityTime[time] === "N",
                            }))}

                            onChange={(e) => setselectAvailabilityTime(e.value)}
                            placeholder="Select Available Time"
                            // placeholder="Select Available Time"
                            className="dropdowntd"
                            optionLabel="label"

                          />

                        )
                         : 
                          ""}
                      <div style={{ color: "red", marginTop: "10px", fontSize: "14px" }}>
                        {errorMessages.scheduleTime}
                      </div>
                    </div>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {timeMessage}
                    </div>
                  </>
                ) : (
                    ""
               
                )}
              </div>
            </div>

            {
              selectdocList || serviceprovider ?
                <div
                  style={{
                    display: "flex",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <label>Service Provider No :</label>
                  </div>
                  <div style={{ width: "60%" }}>
                    {isEditing ? (
                      <>
                        { selectdocList ? (
                          <>{selectdocList?.number}</>
                        )
                          // : isEditing ?   props.serviceProviderMobile
                          :
                           ""
                            // props.serviceProviderMobile
                          }

                     
                      </>
                    ) : ""
                    }

                   
                  </div>
                </div> : ""
            }

           
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Consultation Reason :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {isEditing ? (
                    <>
                      <textarea
                        cols={32}
                        type="text"
                        name="comment"
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                        className="inputfield"
                      />
                    </>
                  ) : (
                   ""
                  )}

                  <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                    {errorMessages.consultationReason}
                  </div>

                 
                </div>
              </div>
           

           
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Consultation Type :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {isEditing ? (
                    <>
                    
                        <Dropdown
                          onChange={(e) => setSelectConsult(e.value)}
                          options={dropdownOptionConsult}
                          optionLabel="name"
                          name="consultationsType"
                          value={selectConsult}
                          
                          placeholder="Select Consultation"
                          className="dropdowntd"
                        />
                      
                    </>
                  )  : (
                    ""
                  )}

                  <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                    {errorMessages.consultationType}
                  </div>

                  {/* <input type="text" placeholder="Consultation Type" className="inputfield" value={props.consultationType}/> */}
                </div>
              </div>
            
          

           
            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
                float: "right",
              }}
            >
            
              { isEditing ? (
                <button
                  type="button"
                  className="cancelbutton"
                  onClick={() => {
                    if (!handleValidation()) {
                      setIsEditing(true); // Open the "Edit Information" section if there are no validation errors
                    }
                  }}
                >
                  Book To Confirm
                </button>
              ) : (
                ""
              )}

             
            </div>
          </div>
        </form>

        <Dialog
          visible={ErrorDialog}
          showHeader={true}
          resizable={false}
          draggable={false}
          blockScroll={false}
          // style={{ width: "1000px" }}
          className=""
          style={{
            width: "390px",
          }}
          //  className="p-20"
          onHide={() => setErrorDialog(false)}
        >
          <div
            style={{
              // width: "100px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <i
              class="pi pi-times-circle"
              style={{ color: "green", fontWeight: "700", fontSize: "75px" }}
            ></i>

            <h4 className="text-xl mt-8" style={{}}>
              {ErrorMsg}
            </h4>
          </div>
        </Dialog>
      </div>

      <Dialog
        visible={openDialog}
        showHeader={true}
        resizable={false}
        draggable={false}
        blockScroll={false}
        // style={{ width: "1000px" }}
        className=""
        header={
          <div className="" style={{ fontSize: "20px", fontWeight: "700" }}>
            Please Confirm !
          </div>
        }
        style={{
          width: "390px",
        }}
        //  className="p-20"
        onHide={() => setDialog(false)}
      >
        <div>
          <div
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "700" }}
          >
            Do you want to confirm the appointment ? Notification will be send
            once confirmed !
          </div>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "47px",
              gap: "50px",
            }}
          >
           
              <>
              

                  <button
                    type="submit"
                    style={{ cursor: "pointer" }}
                    className="addbutton"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {" "}
                    Yes
                  </button> 
                

              </>

          
           
          
            <button className="addbutton" onClick={closeBothFunct}>
              {" "}
              No
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={tickDialog}
        showHeader={true}
        resizable={false}
        draggable={false}
        blockScroll={false}
        // style={{ width: "1000px" }}
        className=""
        // header={<div className="" style={{ fontSize: "20px", fontWeight: "700" }}>Please Confirm !</div>}
        style={{
          width: "390px",
        }}
        //  className="p-20"
        onHide={() => setTickDialog(false)}
      >
        <div
          style={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i
            class="pi pi-check-circle"
            style={{ color: "green", fontWeight: "700" }}
          ></i>
          <h1 className="text-xl mt-8" style={{}}>
            Appointment is booked
          </h1>
        </div>
      </Dialog>
    </>
  );
}
export default PredlabActionSidebar;
