import React from "react";
import status_approved from "../../Assets/Images/status_approved.svg";
import { ReactDialogBox } from "react-js-dialog-box";
import "./index.css";
import "react-js-dialog-box/dist/index.css";

import Loader from "../../components/Loader/Loader";
import { getLocalTime, getLocalOnlyDate } from "../../utils/LocalTimeFormat";

function DoctorAppointmentDetails({
  onClickCancelBtn,
  isSelectedOption,
  orderDetails,
  selectedItem,
  formActivity,
}) {
  const onClickCancel = () => {
    onClickCancelBtn();
  };
  console.log(orderDetails,"hiii");
  console.log("dlssm", JSON.stringify(selectedItem));

  return (
    <div className="dialogContainer">
      <>
        <ReactDialogBox
          closeBox={onClickCancel}
          modalWidth="85%"
          headerBackgroundColor="#004171"
          headerTextColor="white"
          closeButtonColor="white"
          bodyBackgroundColor="white"
          bodyTextColor="black"
          headerText={`${isSelectedOption} - Order ID :  ${
            selectedItem.patientOrderId !== undefined
              ? selectedItem.patientOrderId
              : selectedItem.patientId
          }`}
        >
          {orderDetails === undefined ? (
            <Loader />
          ) : (
            <div className="dialogContainer">
              {isSelectedOption === "Doctor Consultation" ? (
                <div className="main">
                  <div className="titleOfNew">Patient Details</div>
                  <div className="subContainer">
                    <div className="main">
                      <div className="titleMain">Name</div>

                      <div className="titleOfDialog">
                       {orderDetails?.patientAppointmentDTO?.patientName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Mobile</div>

                      <div className="titleOfDialog">
                        {selectedItem.patientOrderId !== undefined
                          ? selectedItem?.patientNumber
                          : selectedItem?.mobileNo}
                      </div>
                    </div>

                    {/* <div className="main">
                  <div className="titleMain">Email</div>

                  <div className="titleOfDialog">
                    {selectedItem?.patientEmailId}
                  </div>
                </div> */}

                    <div className="main">
                      <div className="titleMain">Patient Id</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientAppointmentDTO?.patientId}
                      </div>
                    </div>
                  </div>

                  <div className="titleOfNew">Doctor Details</div>

                  <div className="subContainer">
                    <div className="main">
                      <div className="titleMain">Doctor Name</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientAppointmentDTO?.doctorName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Hospital Name</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientAppointmentDTO?.hospitalName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Location</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientAppointmentDTO?.locationName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Doctor ID</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientAppointmentDTO?.userId}
                      </div>
                    </div>
                  </div>

                  <div className="titleOfNew">Consultations Details</div>

                  <div className="subContainer">
                    <div className="main">
                      <div className="titleMain">Date</div>

                      <div className="titleOfDialog">
                        {getLocalOnlyDate(
                          orderDetails?.patientAppointmentDTO?.appointmentDate
                        )}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Time</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientAppointmentDTO?.fromTime} To{" "}
                        {orderDetails?.patientAppointmentDTO?.toTime}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Reason</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientAppointmentDTO
                          ?.consultationsReason?.length < 50
                          ? orderDetails?.patientAppointmentDTO
                              ?.consultationsReason
                          : orderDetails?.patientAppointmentDTO?.consultationsReason?.substring(
                              0,
                              50
                            ) + "..."}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Type</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientAppointmentDTO
                          ?.consultationsType === "V"
                          ? "Video consultation"
                          : "In Person consultation"}
                      </div>
                    </div>
                  </div>
                </div>
              ) : isSelectedOption === "Hospital Enquiry" ? (
                <div className="main">
                  <div className="titleOfNew">Patient Details</div>
                  <div className="subContainer">
                    <div className="main">
                      <div className="titleMain">Name</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientServiceEnquiryDTO?.patientName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Mobile</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientServiceEnquiryDTO?.patientNumber}
                      </div>
                    </div>

                    {/* <div className="main">
                  <div className="titleMain">Email</div>

                  <div className="titleOfDialog">
                    {orderDetails?.patientServiceEnquiryDTO?.patientEmailId}
                  </div>
                </div> */}

                    <div className="main">
                      <div className="titleMain">Patient ID</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientServiceEnquiryDTO?.patientId}
                      </div>
                    </div>
                  </div>

                  <div className="titleOfNew">Hospital Details</div>
                  <div className="subContainer">
                    <div className="main">
                      <div className="titleMain">Name</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientServiceEnquiryDTO?.hospitalName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Location</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientServiceEnquiryDTO?.locationName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Mobile</div>

                      {/* <div className="titleOfDialog">
                    {orderDetails?.patientServiceEnquiryDTO?.statusName}
                  </div> */}
                    </div>

                    <div className="main">
                      <div className="titleMain">Email</div>

                      {/* <div className="titleOfDialog">
                    {orderDetails?.patientServiceEnquiryDTO?.statusName}
                  </div> */}
                    </div>
                  </div>
                </div>
              ) : isSelectedOption === "Medicine Order" ? (
                <div className="main">
                  <div className="titleOfNew">Patient Details</div>
                  <div className="subContainer">
                    <div className="main">
                      <div className="titleMain">Name</div>

                      <div className="titleOfDialog">
                        {selectedItem?.patientName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Mobile</div>

                      <div className="titleOfDialog">
                        {selectedItem?.patientNumber}
                      </div>
                    </div>

                    {/* <div className="main">
                  <div className="titleMain">Email</div>

                  <div className="titleOfDialog">
                    {selectedItem?.patientEmailId}
                  </div>
                </div> */}

                    <div className="main">
                      <div className="titleMain">Patient Id</div>

                      <div className="titleOfDialog">
                        {selectedItem?.patientId}
                      </div>
                    </div>
                  </div>

                  <div className="titleOfNew"> Pharmacy Order Details</div>

                  <div className="mainTestCode">
                    <div className="titleMain">Name</div>
                    <div className="titleMain">Location</div>
                    <div className="titleMain">Quotation</div>
                    <div className="titleMain">All Medicine Available</div>
                    <div className="titleMain">Mobile</div>
                    <div className="titleMain">Email</div>
                  </div>

                  {orderDetails?.patientMedicineOrderQuotationDTOList?.map(
                    (item) => (
                      <div className="subContainer">
                        <div className="main">
                          <div className="titleOfDialog">
                            {item?.pharmacyName}
                          </div>
                        </div>

                        <div className="main">
                          <div className="titleOfDialog">
                            {item?.pharmacyLocationName}
                          </div>
                        </div>

                        <div className="main">
                          <div className="titleOfDialog">
                            {item?.quotationReceived}
                          </div>
                        </div>

                        <div className="main">
                          <div className="titleOfDialog">
                            {item?.allMedicineAvailable}
                          </div>
                        </div>

                        <div className="main">
                          <div className="titleOfDialog">
                            {item?.pharmacyMobileNumber}
                          </div>
                        </div>

                        <div className="main">
                          <div className="titleOfDialog">
                            {item?.pharmacyEmail}
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  <div className="titleOfNew">Drug/Medicine Details</div>

                  <div className="mainTestCode">
                    <div className="titleMain">Pharmacy name</div>
                    <div className="titleMain">Order ID</div>
                    <div className="titleMain">Drug Code</div>
                    <div className="titleMain">Drug Name</div>
                  </div>

                  {orderDetails?.patientMedicineOrderList?.map((item) => (
                    <div className="medicineList">
                      {item?.patientMedicineOrderDetailsDTOList.map((item) => (
                        <div className="subContainer">
                          <div className="main">
                            <div className="titleOfDialog"></div>
                          </div>

                          <div className="main">
                            <div className="titleOfDialog">
                              {item?.patientOrderId}
                            </div>
                          </div>
                          <div className="main">
                            <div className="titleOfDialog">
                              {item?.drugCode}
                            </div>
                          </div>

                          <div className="main">
                            <div className="titleOfDialog">
                              {item?.drugName}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="main">
                  <div className="titleOfNew">Patient Details</div>
                  <div className="subContainer">
                    <div className="main">
                      <div className="titleMain">Name</div>

                      <div className="titleOfDialog">
                        {selectedItem?.patientName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Mobile</div>

                      <div className="titleOfDialog">
                        {selectedItem.patientOrderId !== undefined
                          ? selectedItem?.patientNumber
                          : selectedItem?.mobileNo}
                      </div>
                    </div>
                    {/* 
                <div className="main">
                  <div className="titleMain">Email</div>

                  <div className="titleOfDialog">
                    {selectedItem?.patientEmailId}
                  </div>
                </div> */}

                    <div className="main">
                      <div className="titleMain">Patient ID</div>

                      <div className="titleOfDialog">
                        {selectedItem.patientOrderId !== undefined
                          ? selectedItem?.patientOrderId
                          : selectedItem?.patientId}
                      </div>
                    </div>
                  </div>

                  <div className="titleOfNew">Lab Details</div>

                  <div className="subContainer">
                    <div className="main">
                      <div className="titleMain">Name</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientLabTestsOrderDTO?.hospitalName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Location</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientLabTestsOrderDTO?.locationName}
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Mobile</div>

                      <div className="titleOfDialog">
                        {
                          orderDetails?.patientLabTestsOrderDTO
                            ?.hospitalMobileNumber
                        }
                      </div>
                    </div>

                    <div className="main">
                      <div className="titleMain">Email</div>

                      <div className="titleOfDialog">
                        {orderDetails?.patientLabTestsOrderDTO?.hospitalEmail}
                      </div>
                    </div>
                  </div>

                  <div className="titleOfNew">Lab Test Details</div>

                  <div className="medicineList">
                    <div className="mainTestCode">
                      <div className="titleMain">Test Code</div>
                      <div className="titleMain">Test Name</div>
                    </div>

                    {orderDetails?.patientLabTestsOrderDTO?.patientLabTestsOrderDetailsDTOList.map(
                      (item) => (
                        <div className="subContainer">
                          <div className="main">
                            <div className="titleOfDialog">
                              {item?.labTestCode}
                            </div>
                          </div>

                          <div className="main">
                            <div className="titleOfDialog">
                              {item?.labTestName}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}

              

                <div className="main">
                  <div className="titleOfNew">Order Event Log</div>
                  <div className="mainTestCode">
                    <div className="titleMain">Activity Status</div>
                    <div className="titleMain">Time</div>
                  </div>
                  {
                    <div className="medicineList">
                      {orderDetails?.eventLogList?.map((item) => (
                        <div className="subContainer">
                          <div className="main">
                            <div className="titleOfDialog"> 
                              {item?.description}
                            </div>
                          </div>
                          <div className="main">
                            <div className="titleOfDialog">
                              {getLocalTime(item?.createdDate)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>



                <div className="main">
                  <div className="titleOfNew">Back Office Log</div>
                  <div className="mainTestCode">
                    <div className="titleMain">Action</div>
                    <div className="titleMain">CreatedBy</div>
                    <div className="titleMain">Remarks</div>
                    <div className="titleMain">Time</div>
                  </div>
                  {
                    <div className="medicineList">
                      {orderDetails?.backOfficeEventLog.map((item) => (
                        <div className="subContainer">
                          <div className="main">
                            <div className="titleOfDialog">
                              {item?.action}
                            </div>
                          </div>

                          <div className="main">
                            <div className="titleOfDialog">
                              {item?.createdBy}
                            </div>
                          </div>

                          <div className="main">
                            <div className="titleOfDialog">
                              {item?.remarks}
                            </div>
                          </div>

                          <div className="main">
                            <div className="titleOfDialog">
                              {getLocalTime(item?.defaultDateTime)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              
            </div>
          )}
        </ReactDialogBox>
      </>
    </div>
  );
}

export default DoctorAppointmentDetails;
