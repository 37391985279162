import React, { useEffect, useState } from "react";
import http from "../../../src/Redux/services/http-common.jsx";

import { ToggleButton } from "primereact/togglebutton";

import Labcart from "./LabCartPage.jsx";


function SidebarMedicine({ scheduledata, closeFun }) {
  const [SelectcartType, setSelectcartType] = useState(true);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [freeTextSearchValue, setFreeTextSearchValue] = useState("");
  const [freeTextSearchValueLab, setFreeTextSearchValueLab] = useState("");
 
  const [showDropdown, setShowDropdown] = useState(false);
  const [cart, setCart] = useState([]);
  const [cartId, setCartId] = useState();
  const [medicineCart, setMedicineCart] = useState(null);

  const ontoggleChange = (e) => {
    setSelectcartType(e.value);
  };

  const fetchDropdownOptions = async (query) => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}medicine?freeTextSearch=${query}&pageNo=1&pageSize=100`
      );
      const options = response.data.map((item) => ({
        label: item.medicineName,
        value: item,
      }));
      setDropdownOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const fetchCartList = async () => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASEURL}cart/list?patientId=${scheduledata?.patientCode}`
      );
      const cartId = response?.data?.cartId;
      console.log(cartId, "gbbgbg");
      setCartId(cartId);

      const cartData = response.data.medicineCart;

      console.log(cartData, "sdxcfgvh");
      setMedicineCart(cartData);

      if (response?.data?.medicineCart?.patientMedicineOrderDetailsList) {
        // Extract cart items from the response
        const existingCart =
          response.data.medicineCart.patientMedicineOrderDetailsList.map(
            (item) => ({
              label: item.drugName,
              value: {
                id: item.drugCode,
                medid: item.id,
                medicineid: item.id,
                drugName: item.drugName,
                drugCategory: item.drugCategory,
                unitPrice: item.unitPrice,
                discountAmount: item.discountAmount,
                prescriptionRequired: item.prescriptionRequired,
                vendorId: null,
              },
              quantity: item.quantity,
            })
          );
        setCart(existingCart);
      }
    } catch (error) {
      console.error("Error fetching cart list:", error);
    }
  };
  console.log(SelectcartType, "fgvbh");
  useEffect(() => {
    // Fetch the existing cart when the component mounts
    if (scheduledata?.patientCode) {
      fetchCartList();
    }
  }, [scheduledata?.patientCode]);

  useEffect(() => {
   
      if (freeTextSearchValue.trim() !== "") {
        setShowDropdown(true);
        const delayDebounce = setTimeout(() => {
          fetchDropdownOptions(freeTextSearchValue);
        }, 300);
        return () => clearTimeout(delayDebounce);
      } else {
        setShowDropdown(false);
        setDropdownOptions([]);
      }
  
  }, [freeTextSearchValue]);

  const addToCart = async (medicine) => {
    // Validation to check if the medicine is already in the cart
    const existingItem = cart.find(
      (item) => item.value.id === medicine.value.id
    );
    if (existingItem) {
      alert("This medicine is already in the cart.");
      return;
    }

    const payload = {
      patientId: scheduledata?.patientCode,
      drugCode: medicine?.value.id,
      drugName: medicine.value.medicineName,
      drugCategory: medicine.value.drugCategory,
      medicineTypeOfSell: medicine.value.medicineTypeOfSell,
      medicineType: "",
      unitPrice: Number(medicine?.value?.medicineRate),
      quantity: 1,
      prescriptionRequired: medicine.value.prescriptionRequired,
      vendorId: medicine.value.vendorId,
    };
    console.log(medicine?.value, payload, "dfgvhhh");
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASEURL}newPatientMedicine/`,
        payload
      );

      console.log("API Response:", response);
      fetchCartList();
      setCart((prevCart) => [...prevCart, { ...medicine, quantity: 1 }]);

      setShowDropdown(false);
      setFreeTextSearchValue("");
    } catch (error) {
      console.error("Error adding medicine to cart:", error);
    }
  };

  const updateQuantity = async (id, newQuantity) => {
    console.log(id, "rggtgb");
    if (newQuantity <= 0) {
      setCart((prevCart) => prevCart.filter((item) => item.value.id !== id));
    } else {
      try {
        // Call the API to update the quantity
        await http.get(
          `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?quantity=${newQuantity}&Id=${id}`
        );

        // Update the local cart state
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.value.id === id ? { ...item, quantity: newQuantity } : item
          )
        );

        // Fetch the updated cart list
        fetchCartList();
      } catch (error) {
        console.error("Error updating quantity:", error);
      }
    }
  };
  const deleteItem = async (id) => {
    try {
      // Call the API to delete the item
      await http.get(
        `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?status=0&Id=${id}`
      );

      setCart((prevCart) => prevCart.filter((item) => item.value.id !== id));

      fetchCartList();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const submitCart = async () => {
    try {
      // Call the API to delete the item
      const response = await http.post(
        `${process.env.REACT_APP_BASEURL}newPatientMedicine/saveWhatsappUploadedPrescription?cartId=${cartId}&docName=${scheduledata?.docName}`
      );

      console.log(response, "fcghhhyhy");
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <div>
      <div></div>
      <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px" }}>
        <div style={{}}>
          <label htmlFor="doctors" className="">
            Cart Type:
          </label>
        </div>
        <div style={{ marginLeft: 20 }}>
          <ToggleButton
            onLabel="Pharmacy"
            offLabel="Lab"
           
            checked={SelectcartType}
            onChange={(e) => setSelectcartType(e.value)}
          />
        </div>
      </div>

      {SelectcartType ? (
        //  pharmacy cart
        <div
          style={{
            padding: "20px",
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          {/* Input Field */}
          <div style={{ marginBottom: "20px" }}>
            <label
              style={{ fontWeight: "bold", fontSize: "14px", color: "#333" }}
            >
              Search Medicine Name:
            </label>
            <div style={{ position: "relative", marginTop: "8px" }}>
              <input
                type="text"
                value={freeTextSearchValue}
                onChange={(e) => setFreeTextSearchValue(e.target.value)}
                placeholder="Type to search medicine"
                style={{
                  width: "100%",
                  padding: "12px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              {showDropdown && dropdownOptions.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    zIndex: 1000,
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {dropdownOptions.map((option, index) => (
                    <div
                      key={index}
                      onClick={() => addToCart(option)}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderBottom: "1px solid #f0f0f0",
                        backgroundColor: "#fff",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#f7f7f7")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "#fff")
                      }
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Cart Display */}
          <div style={{ marginTop: "20px" }}>
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              Selected Medicines
            </h3>

            {/* Membership Status Section */}
            {medicineCart?.membershipCode ? (
              <div
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  backgroundColor: "#e6f7e6",
                  color: "#155724",
                }}
              >
                <h4 style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Membership Status: Membership Active
                </h4>
              </div>
            ) : (
              ""
            )}

            {cart.length > 0 ? (
              <ul style={{ listStyle: "none", padding: 0 }}>
                {cart.map((item) => (
                  <li
                    key={item.value.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      padding: "10px",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{item.label}</span>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "4px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "0.9rem",
                            color: "#999",
                            textDecoration: "line-through",
                          }}
                        >
                          ₹{item.value.unitPrice}
                        </span>
                        <span
                          style={{
                            fontSize: "0.9rem",

                            fontWeight: "bold",
                          }}
                        >
                          ₹{item?.value?.discountAmount}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <button
                        onClick={() =>
                          updateQuantity(item.value.medid, item.quantity - 1)
                        }
                        style={{
                          padding: "5px 10px",
                          border: "none",
                          borderRadius: "4px",
                          backgroundColor: "#f44336",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button
                        onClick={() =>
                          updateQuantity(item.value.medid, item.quantity + 1)
                        }
                        style={{
                          padding: "5px 10px",
                          border: "none",
                          borderRadius: "4px",
                          backgroundColor: "#4CAF50",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        +
                      </button>

                      <button
                        onClick={() => deleteItem(item.value.medid)}
                        style={{
                          padding: "5px 10px",
                          border: "none",
                          borderRadius: "4px",
                          backgroundColor: "#f44336",
                          color: "#fff",
                          cursor: "pointer",
                          marginLeft: "8px",
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No medicines selected.</p>
            )}
          </div>

          {/* Cart Summary */}
          {medicineCart && (
            <div
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "4px",
              }}
            >
              <h4
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
              >
                Cart Summary
              </h4>
              <p style={{ marginBottom: "5px" }}>
                Subtotal:{" "}
                <span style={{ fontWeight: "bold" }}>
                  ₹{medicineCart.amount}
                </span>
              </p>
              <p style={{ marginBottom: "5px" }}>
                Discount:{" "}
                <span style={{ fontWeight: "bold" }}>
                  ₹{(medicineCart.amount - medicineCart.paidAmount).toFixed(2)}
                </span>
              </p>
              <p style={{ marginBottom: "5px" }}>
                Paid Amount:{" "}
                <span style={{ fontWeight: "bold" }}>
                  ₹{medicineCart.paidAmount}
                </span>
              </p>
            </div>
          )}

          <div style={{ marginTop: "20px" }}>
            <button
              onClick={submitCart}
              style={{
                padding: "10px 20px",
                border: "none",
                borderRadius: "4px",
                backgroundColor: "#4CAF50",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Submit Cart
            </button>
          </div>
        </div>
      ) : (
        ""
        // <Labcart/>
      )}
    </div>
  );
}

export default SidebarMedicine;
