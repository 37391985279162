import DatePicker from "react-datepicker";
import { useState, React, useRef } from "react";
import { useEffect } from "react";
import http from "../../../src/Redux/services/http-common.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "../CarePlan/index.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import DoctorAppointmentViewPopup from "./DoctorAppointmentViewPopup.js";

import { Tag } from 'primereact/tag';
import { Dropdown } from "primereact/dropdown";
import { Toast } from 'primereact/toast';
import { Calendar } from "primereact/calendar";
import SidebarMedicine from "./SidebarMedicine.jsx";
import { Sidebar } from "primereact/sidebar";

function Index() {
    const toast = useRef(null);
    const [searchResults, setSearchResults] = useState(undefined);
    const [fromDate, setFromDate] = useState(new Date());
    const [completedRows, setCompletedRows] = useState({});
    const [sideDialogue,setSideDialog]= useState();
    const [selectedRow,setSelectedRow] =useState();

    const handleSearchSubmit = () => {
      const formattedDate= moment(fromDate).format('DD/MM/YYYY');
      console.log(formattedDate,"swedrftgyh")
        const url = `${process.env.REACT_APP_BASEURL}PatientDocument/backoffice/listAllReport/filter?fromDate=${formattedDate}`;
        
        console.log("Fetching URL:", url);

        http.get(url)
            .then((res) => {
                console.log("Response Data:", res.data);
                setSearchResults(res.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };
useEffect(()=>{
    handleSearchSubmit();
},[])

const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setSideDialog(true);
  };
    const handleStatusChange = (selectedValue, rowData) => {
        if (selectedValue === "Completed") {
            const url = `${process.env.REACT_APP_DOC_URL}PatientDocument/backoffice/complete`;
            const payload = {
                callBackPrescriptionFlag: "C",
                code: rowData.code,
            };

            console.log("Payload:", payload);

            http.put(url, payload)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("Update Response:", res.data);
                        toast.current.show({
                            severity: "success",
                            summary: "Success",
                            detail: "Prescription enquiry marked as completed",
                            life: 3000,
                        });
                        setCompletedRows((prev) => ({
                            ...prev,
                            [rowData.code]: true,
                        }));
                    }
                })
                .catch((error) => {
                    console.error("Error updating status:", error);
                });
        }
    };

    const renderActionColumn = (rowData) => {
        return completedRows[rowData.code] ? (
            <Tag
                severity="success"
                value="Completed"
                style={{ backgroundColor: "green", color: "white" }}
            />
        ) : (
            <Dropdown
                value={null}
                options={[{ label: "Completed", value: "Completed" }]}
                placeholder="Select Action"
                onChange={(e) => handleStatusChange(e.value, rowData)}
            />
        );
    };

    return (
        <>
            <Toast ref={toast} />

            <div style={{ padding: "10px" }}>
                <h3>Select Date</h3>
                <Calendar
                    value={fromDate}
                    onChange={(e) => setFromDate(e.value)}
                    placeholder="Select Date"
                    dateFormat="yy-mm-dd"
                    showIcon
                />
                <button onClick={handleSearchSubmit} className="p-button p-component p-button-primary">
                    Search
                </button>
            </div>

            {searchResults === undefined ? (
                <div
                    style={{
                        height: "1000px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <i
                        className="pi pi-spin pi-spinner"
                        style={{
                            fontSize: "2rem",
                            color: "blue",
                            fontWeight: "700",
                        }}
                    />
                </div>
            ) : (
                <DataTable
                    value={searchResults}
                    paginator
                    rows={50}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    globalFilterFields={["patientName", "mobile"]}
                    tableStyle={{ padding: "10px" }}
                    header={<div className="flex flex-wrap justify-content-end gap-2"></div>}
                    rowClassName={(rowData) =>
                        rowData.serviceStatus === 2 ? "rowcolor" : ""
                    }
                >
                    <Column sortable header="Patient Name" field="patientName" />
                    <Column sortable header="Patient Number" field="patientMobile" />
                    <Column sortable header="Prescription" field="docName"  body={(rowData) => (
    <a
      href={`${process.env.REACT_APP_IMG_BASEURL}${rowData.docName}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: 'blue', textDecoration: 'underline' }}
    >
      {rowData.docName}
    </a>
  )}/>
   <Column
              header="Action"
              body={(rowData) => (
                <>
                  <span>
                  
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                        onClick={() => handleRowClick(rowData)}
                        style={{ cursor: "pointer" }}
                      ></i>
                   
                  </span>
                </>
              )}
            />
                   
                </DataTable>

                
            )}

<Sidebar
            visible={sideDialogue}
            position="right"
            onHide={() => setSideDialog(false)}
            style={{ width: "480px" }}
          >
            <>
              <SidebarMedicine
                scheduledata={selectedRow}
                
               
                closeFun={() => setSideDialog(false)}
                
              />
              {/* ... */}
            </>
          </Sidebar>
        </>
    );
}
export default Index;